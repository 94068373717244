import axios from 'axios';

const BASE_URL = process.env.VUE_APP_BASE_URL;

const token = sessionStorage.getItem('token');
axios.defaults.headers.common.Authorization = `Bearer ${token}`;

const createUser = async ({
    email, name, sector_id, manager_id, profile_id, phone, whatsapp_group_profile_id,
}) => {
    try {
        return await axios.post(`${BASE_URL}/backoffice/user`, {
            email,
            name,
            sector_id,
            manager_id,
            profile_id,
            phone,
            whatsapp_group_profile_id,
        });
    } catch ({ response }) {
        return response;
    }
};
const listAllUsers = async () => {
    try {
        return await axios.get(`${BASE_URL}/backoffice/user`);
    } catch ({ response }) {
        return response;
    }
};
const findUser = async id => {
    try {
        return await axios.get(`${BASE_URL}/backoffice/user/${id}`);
    } catch ({ response }) {
        return response;
    }
};
const updateUser = async ({
    name, sector, manager, profile, whatsAppProfile, ...rest
}, id) => {
    try {
        return await axios.put(`${BASE_URL}/backoffice/user/${id}`, {
            name,
            sector_id: sector,
            manager_id: manager,
            profile_id: profile,
            whatsapp_group_profile_id: whatsAppProfile,
            ...rest,
        });
    } catch ({ response }) {
        return response;
    }
};

const revokeUser = async id => {
    try {
        const { status } = await axios.delete(`${BASE_URL}/backoffice/user/${id}`);
        return status;
    } catch ({ response }) {
        return response.status;
    }
};

export {
    createUser,
    listAllUsers,
    findUser,
    updateUser,
    revokeUser,
};
